/**
 * axios接口实例和拦截
 */
import axios from 'axios'
import path from '@/api/path'
import store from '@/store'

const resetMessage = require('../utils/resetMessage')
const Message = resetMessage.message

// 创建axios 实例
const { baseUrl } = require('@/config') // node proxy service url
let source = axios.CancelToken.source()

const service = axios.create({
  baseURL: baseUrl,
  timeout: 300000, // 超时时间
  cancelToken: source.token
})

// 状态码判断
const errorHandle = (status) => {
  switch (status) {
    case 401:
      Message.error('401错误')
      break
    // 404请求不存在
    case 404:
      Message.error('404错误，请求的资源不存在')
      break
    case 500:
      Message.error('500错误，服务器内部错误')
      break
    default:
      Message.error('服务器错误')
      break
  }
}


// console.log(source.token.reason)
// 请求拦截器:
service.interceptors.request.use((config) => {
  const token = localStorage.getItem('token') // token
  console.log(typeof source.token.reason!='undefined')
  if(typeof source.token.reason=='undefined'){
    source=axios.CancelToken.source()
  }
 
 
  if (token) {
    // 头部加入验证信息
    config.headers.Authorization = 'Bearer ' + token
  }

  return config
})

// 响应拦截器
service.interceptors.response.use(
  // success
  (res) => {
    if (res.config.url.includes(path.file.downupload)) {
      // 如果返回的是二进制流
      return res
    } else {
      const { message } = res.data
      if (message === 'Success') {
        return res.data
      } else {
        if (message === 'user need login') {
          Message.error('用户信息失效，请重新登录')
          // 退出当前用户信息
          store.dispatch('system/setUserInfo', null)
          store.dispatch('system/setUserBaseInfo', null)
          localStorage.clear()
          source.cancel('request canceled because userinfo lose effectiveness') // 取消请求
          
        } else {
          Message.error(message)
        }
        return new Error(res)
      }
    }
  },
  (error) => {
    if (error.message.includes('timeout')) {
      console.log('请求超时')
      Message.error('请求超时')
    } else if (error.message.includes('canceled')) {
      console.log('中止请求')
    } else {
      if (error.response && error.response.status) {
        errorHandle(error.response.status)
      } else {
        Message.error('请求错误')
      }
    }

    return Promise.reject(error)
  }
)

export default service
