<template>
  <div id="app">
    <router-view />
    <!-- 将上传组件全局注册 -->
    <global-uploader />
    <div
        class="text-center py-0.1rem isshow min-w-0.2rem p-0.05rem "
        v-if="flage"
        style="
          position: fixed;
          right: 0;
          top: 2rem;
          background: #663399;
          border-radius: 5px;
          z-index: 1000;
        "
      >
        <span
          class="text-0.1rem text-slate-50 w-0.1rem showfont"
          style="display: inline-block"
          >联系合作及建议反馈</span
        >
        <img
          src="@/assets/images/space/wxcode_r2_c2.jpg"
          alt=""
          class="w-0.8rem showvx mb-0.2rem "
          style="margin: auto;  position: relative;"
        />
        <img src="@/assets/images/close.png" alt="" style="position: absolute; top: 2px; right: 2px;" class="w-0.1rem cursor-pointer showvx" @click="closetip">
        <span class="w-0.86rem showvx text-slate-50 text-0.07rem p-0.02rem mt-0.03rem " style="line-height: 0.1rem;">有任何具体合作想法，
          或者在平台使用中遇到任何问题，可以添加小助手wisemodel，或者<a class="under cursor-pointer" @click="jumpsuggestion"><span >提交建议</span></a></span>
      </div>
  </div>
</template>

<script>
import GlobalUploader from '/vue-simple-uploader/GlobalUploader'
import { init } from '@/utils/getSelectOptions'
export default {
  components: {
    GlobalUploader
  },
  data() {
    return {
      flage:true
    }
  },
  created() {
        
    if( sessionStorage.getItem('languageType')){
      let data = sessionStorage.getItem('languageType')
      if(data==1){
        this.$i18n.locale = 'zh-CN';
      }
      if(data==2){
        this.$i18n.locale = 'en-US';
      }
    }
  },
  mounted() {
    init()
  },
  methods: {
    closetip(){
        this.flage=false
    },
    jumpsuggestion(){
      this.$router.replace('/suggestion')
    }
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
.showvx {
  display: none;
}
.isshow:hover .showvx {
  display: block;
}
.isshow:hover .showfont {
  display: none !important;
}

.under{
  padding-bottom: 0px;
    border-bottom:  1px solid #48C2F9;
}
</style>
